import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useShare} from '../hooks/share.hooks';
import {goBack} from '@utils';
import {ReactComponent as LeftArrow} from './assets/left-arrow.svg';
const bg = require('./assets/agent.webp');
const copy = require('./assets/copy.webp');
import './agent-page.css';

const AgentPage = () => {
  const {i18n} = useTranslation();
  const {code, doShare, initShare} = useShare();
  useEffect(() => {
    initShare();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="agent-page">
      <div className="nav-wrap">
        <LeftArrow className="goback-arrow" onClick={goBack} />
        <h1 className="nav-title">{i18n.t('proxy.title')}</h1>
      </div>
      <img src={bg} alt="agent bg" className="agent-bg" />
      <div className="input-wrap">
        <div className="code">
          {code?.split('').map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        </div>
        <div onClick={() => doShare()}>
          <img src={copy} alt="agent copy" className="copy" />
        </div>
      </div>
    </div>
  );
};

export default AgentPage;
